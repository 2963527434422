import Swiper from "swiper/bundle";

const [d] = [document];

export class worksBlock {

  constructor () {
    this.render();
  }

  render () {
    const getArticles = this.fetchArticles({
      content: 'works',
      post_per_page: 10
    });
    const getCategories = this.fetchCategories('works');
    const dispTags = tags => {
      tags = tags.map(t => `<span class="tag">#${t}</span>`);
      return `<span class="tags">${tags.join('')}</span>`;
    };
    const appendSlide = (item,slider) => {
      slider.appendSlide([`
        <a href="${item.href}" class="works-item swiper-slide">
          ${(item.category.name)? `<span class="cat">${item.category.name}</span>`: ''}
          <div class="works-item__img-wrap">
            <img src="${item.thumb}" alt="${item.title}" class="works-item__img" width="355" height="355">
          </div>
          <div class="works-item__body">
            <span class="title">${item.title}</span>
            ${(item.keywords)? dispTags(item.keywords):''}
          </div>
          <!-- /.works-item__body -->
        </a>
        <!-- /.works-item -->
      `]);
    }
    const slider = new Swiper('.block-works__list', {
      grabCursor: true,
      slidesPerView: "auto",
      loop: true,
      spaceBetween: 12,
      breakpoints: {
        900: {
          spaceBetween: 46
        }
      },
      navigation: {
        nextEl: '.block-works .swiper-button-next',
        prevEl: '.block-works .swiper-button-prev',
      },
      pagination: {
        el: '.block-works .swiper-pagination',
        type: 'progressbar',
      },
    });
    slider.removeAllSlides();
    Promise.all([getArticles,getCategories]).then( ([articles,cats]) => {
      d.querySelector('.block-works__slider').innerHTML = '';
      articles.map( article => {
        article.category = cats.find( v => v.id == article.category ) || {id: '0', href: '/works/?mode=list&cat=0', name: 'その他', turn: 99};
        appendSlide(article,slider);
      });
      slider.slideToLoop(0);
    });
  }

  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}