import { worksBlock } from "../class/works-block";

const [w,d] = [window,document];

const domReady = () => {};

const cb_aboutus01 = res => {
  if( res ) new worksBlock;
}

export default function ABOUTUS () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => {
    let promise_about01 = w.load['aboutus-01'];
    (promise_about01)? promise_about01.then( cb_aboutus01 ) : false;
  });
}