import subNav from "../class/subNav";
const [d] = [document];

export default class customSubNav extends subNav {
  constructor(opt) {
    super(opt);
  }

  /**
   * サブナビゲーション追加用メソッド
   * @param {string} navSelector // ナビゲーションセレクタ(opt.gnav or opt.fnav)
   * @param {string} key // data-key属性値
   * @param {array} itemlist // アイテムリスト
   * @param {string} contName // コンテンツ名
   */
  add (navSelector=this.opt.gnav, key, itemlist=false, contName=false) {
    const Navs = d.querySelectorAll(navSelector);

    // Collapseボタンを追加する(itemlistのループで使用)
    const addCollapse = (elm,navKey) => {
      if( elm.dataset.collapse.toLowerCase() === 'true' ) {
        const Btn = d.createElement('button');
        const btnClass = () => {
          switch( navKey ) {
            case this.opt.gnav: return `${this.opt.subnavClass}__tgl`;
            case this.opt.fnav: return `${this.opt.footerSubnavClass}__tgl`;
          }
        };
        Btn.classList.add( btnClass() );
        Btn.type = 'button';
        Btn.dataset.plugin = 'collapse';
        elm.appendChild(Btn);
      }
    }

    // サブナビを追加する(itemlistのループで使用)
    const addItem = (elm,navKey) => {
      const tempEl = d.createElement('div');
      let innerHtml = '';
      let outerHtml = '';
      itemlist.forEach( item => {
        switch( navKey ) {
          case this.opt.gnav: {
            innerHtml += item.html || this.itemTemplate(item);
          } break;
          case this.opt.fnav: {
            innerHtml += item.footerHtml || this.footerItemTemplate(item);
          } break;
        }
      });
      switch( navKey ) {
        case this.opt.gnav: outerHtml = this.outerTemplate(innerHtml,contName); break;
        case this.opt.fnav: outerHtml = this.footerOuterTemplate(innerHtml); break;
      }
      tempEl.insertAdjacentHTML('beforeend', outerHtml);
      elm.appendChild( tempEl.firstElementChild );
    }

    if( itemlist ) {
      Navs.forEach( Nav => {
        const menus = Nav.querySelectorAll(`[data-key="${key}"]`);
        menus.forEach( Menu => addCollapse(Menu, navSelector));
        menus.forEach( Menu => addItem(Menu, navSelector));
      });
    }
  }

  // デフォルトのサブメニューアイテムHTMLをカスタム（上書き）
  itemTemplate (item) { return `
  <li class="${this.opt.subnavClass}-item is-custom">
    <a href="${item.href}" class="${this.opt.subnavClass}-item__link"><span>${item.label}</span></a>
  </li>
  `;}

  // デフォルトのサブメニュー外包HTMLをカスタム（上書き）
  outerTemplate (html,contName) { return `
  <div class="${this.opt.subnavClass} is-custom">
    <div class="${this.opt.subnavClass}__inner">
      ${(contName)? `<h2 class="${this.opt.subnavClass}__contName d-none d-sm-block">${contName}</h2>`: ''}
      <ul class="${this.opt.subnavClass}__list">${html}</ul>
    </div>
  </div>
  `;}

  // フッターサブナビ個別アイテム用テンプレートの上書き（指定しない場合はヘッダーと共通）
  footerItemTemplate (item) { return `
  <li class="${this.opt.footerSubnavClass}-item">
    <a href="${item.href}" class="${this.opt.footerSubnavClass}-item__link"><span>${item.label}</span></a>
  </li>
  `;}
  
  // フッターサブナビ用テンプレートの上書き（指定しない場合はヘッダーと共通）
  footerOuterTemplate (html) { return `
  <div class="${this.opt.footerSubnavClass}">
    <div class="${this.opt.footerSubnavClass}__inner">
      <ul class="${this.opt.footerSubnavClass}__list">${html}</ul>
    </div>
  </div>
  `;}
}